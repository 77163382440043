.NavContainerStyled,
.active {
    display: flex;
    flex-direction: column;
}

.NavContainerStyled a > span {
    color: theme(colors.chroma.400);
    transition: color 0.4s ease-in-out;
}

.NavContainerStyled:hover > a > span,
.NavContainerStyled:focus-within > a > span,
.NavContainerStyled:active > a > span,
.active > a > span {
    color: theme(colors.chroma.100);
}

.NavContainerStyled .NavChildStyled,
.active .NavChildStyled {
    position: relative;
    width: 100%;
    height: 1px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.NavContainerStyled:hover > .NavChildStyled,
.NavContainerStyled:focus-within > .NavChildStyled,
.NavContainerStyled:active > .NavChildStyled,
.active > .NavChildStyled {
    opacity: 1;
}
